import React, { useState } from 'react';
import Projects from '../../api/project'
import ProjectSingle from '../ProjectSingle/ProjectSingle';


const ProjectSection = (props) => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const [number, setCount] = useState(3);
    const [buttonActive, setButtonState] = useState(false);

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }
    return (

        <section className="tp-project-section section-padding">
            <div className="container">
                <div className="tp-section-title">
                    <span>Projects</span>
                    <h2>Previous Work</h2>
                </div>
                <div className="tp-project-wrap">
                    <div className="row">
                        {Projects.slice(0).map((project, pro) => (
                            <div className="col col-xl-4 col-lg-6 col-sm-12 col-12" key={pro}>
                                <div className="tp-project-item">
                                    <div className="tp-project-img" onClick={() => handleClickOpen(project)}>
                                        <img src={project.pImg} alt="" />
                                    </div>
                                    <div className="tp-project-content">
                                        <span>{project.subTitle}</span>
                                        <h2 onClick={() => handleClickOpen(project)}>{project.title}</h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ProjectSingle open={open} onClose={handleClose} title={state.title} pImg={state.pheader} processImg={state.processImg} psub1img1={state.psub1img1} psub1img2={state.psub1img2} location={state.location} 
            type={state.type} duration={state.duration} completion={state.completion} overview={state.overview} strategy={state.strategy} stratPoint1={state.stratPoint1}
            stratPoint2={state.stratPoint2} stratPoint3={state.stratPoint3} approach={state.approach} goalPoint1={state.goalPoint1} goalPoint2={state.goalPoint2}
            goalPoint3={state.goalPoint3} resultPoint1={state.resultPoint1} resultPoint2={state.resultPoint2} resultPoint3={state.resultPoint3} ps1={state.ps1} ps2={state.ps2} siteURL={state.siteURL} liveSite={state.liveSite}/>


        </section>
    );
}

export default ProjectSection;