import React from 'react'

const Marquee = (props) => {
    return (
        <div className="digital-marque-sec">
            <div className="container">
                <div className="digital-marque">
                    <div className="marquee">
                        <div className="track">
                            <div className="content">
                                <h1><span>Great design is the tool used to tell your story.</span><span> Let's find it.</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marquee;