import sgImgS1 from '../images/service-single/web/img-1.jpg'
import sgImgS2 from '../images/service-single/web/img-2.jpg'
import sgImgS3 from '../images/service-single/web/img-3.jpg'

import brImgS1 from '../images/service-single/app/img-1.jpg'
import brImgS2 from '../images/service-single/app/img-2.jpg'
import brImgS3 from '../images/service-single/app/img-3.jpg'

import uxImgS1 from '../images/service-single/brand/img-1.jpg'
import uxImgS2 from '../images/service-single/brand/img-2.jpg'
import uxImgS3 from '../images/service-single/brand/img-3.jpg'

import dvImgS1 from '../images/service-single/market/img-1.jpg'
import dvImgS2 from '../images/service-single/market/img-2.jpg'
import dvImgS3 from '../images/service-single/market/img-3.jpg'



const Services = [
    {
        Id: '1',
        sImgS:sgImgS1,
        sTitle: 'Website Design & Development',
        description:'Provide layouts, interfaces, and a design that can give your users an amazing experience. Backed by detailed research and strategy',
        icon:'flaticon-vector',
        projects:'213',
        ssImg1:sgImgS2,
        ssImg2:sgImgS3,
    },
    {
        Id: '2',
        sImgS:brImgS1,
        sTitle: 'Mobile App Design & Development',
        description:'My work involves creating and building mobile applications in accordance with the current standards and specifications of mobile devices',
        icon:'flaticon-smartphone',
        projects:'102',
        ssImg1:brImgS2,
        ssImg2:brImgS3,
    },
    {
        Id: '3',
        sImgS:uxImgS1,
        sTitle: 'Product Design & Development',
        description:'My expertise lies in devising solutions using digital products. I possess the proficiency to aid in both designing and developing React-based applications',
        icon:'flaticon-palette',
        projects:'60',
        ssImg1:uxImgS2,
        ssImg2:uxImgS3,
    },
    {
        Id: '4',
        sImgS:dvImgS1,
        sTitle: 'User Studies and Research',
        description:'Research your industry and users to help find the best way to get you ahead of everyone',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-bar-chart',
        projects:'230',
        ssImg1:dvImgS2,
        ssImg2:dvImgS3,
    }
]    

export default Services;