import React from "react";
import { NavLink } from 'react-router-dom'
import himg from '../../images/slider/right-img.png'
import { Link } from 'react-scroll'
import Logo from '../../images/logo.png'

const Hero =() => {
    return (
        <section className="tp-hero-section-1">
            <div className="container">
                <div className="row">
                    <div>
                        <div className="tp-hero-section-text">
                            <div className="tp-hero-title">
                            <img src={Logo} alt="" />
                                <h2>Julian Conde</h2>
                            </div>
                            <div className="tp-hero-sub">
                                <p>UX Designer / Developer</p>
                            </div>
                            <div className="btns">
                                <Link activeClass="active" onClick={(e) => {window.location.href ='mailto:julian@julianconde.com';}} spy={true} smooth={true} duration={500} offset={-95} className="theme-btn">BOOK A CALL</Link>
                            </div>
                            <div style={{marginTop: 24}} className="btns">
                                <Link activeClass="active" to="project" spy={true} smooth={true} duration={500} offset={-95} className="theme-btn">SEE MY WORK</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="social-link">
                <ul>
                    <li><NavLink to="https://dribbble.com/jcondedesign">Dribbble</NavLink></li>
                    <li><NavLink to="https://www.twitter.com/julianxconde">Twitter</NavLink></li>
                    <li><NavLink to="https://www.linkedin.com/in/julianconde">LinkedIn</NavLink></li>
                </ul>
            </div>
        </section>
    )
}

export default Hero;