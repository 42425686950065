import React from 'react'
import aImg from '../../images/about/img-1.png'

const About = (props) => {
    return (

        <section className="tf-about-section section-padding">
            <div className="container">
                <div className="tf-about-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="tf-about-img">
                                <img src={aImg} alt="" />
                                <div className="tf-about-img-text">
                                    <div className="tf-about-icon">
                                        <h3>8+</h3>
                                        <span>Years Exprience</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="tf-about-text">
                                <small>about Me</small>
                                <h2>Hello</h2>
                                <h5>I have 8+ years of experiences in Web, UX design and development.</h5>
                                <p>With over 8 years of experience, I possess extensive expertise in web development, UX design, and development.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;