import pimg1 from '../images/project/img-1.jpg'
import pimg2 from '../images/project/img-2.jpg'
import pimg3 from '../images/project/img-3.jpg'
import pimg4 from '../images/project/img-4.jpg'
import pimg5 from '../images/project/img-5.jpg'
import pimg6 from '../images/project/img-6.jpg'

import pheader1 from '../images/project-single/1.jpg'
import pheader2 from '../images/project-single/2.jpg'
import pheader3 from '../images/project-single/3.jpg'
import pheader4 from '../images/project-single/4.jpg'
import pheader5 from '../images/project-single/5.jpg'
import pheader6 from '../images/project-single/6.jpg'

import p1i1 from '../images/project-single/p1i1.jpg'
import p1i2 from '../images/project-single/p1i2.jpg'
import p2i1 from '../images/project-single/p2i1.jpg'
import p2i2 from '../images/project-single/p2i2.jpg'
import p3i1 from '../images/project-single/p3i1.jpg'
import p3i2 from '../images/project-single/p3i2.jpg'
import p4i1 from '../images/project-single/p4i1.jpg'
import p4i2 from '../images/project-single/p4i2.jpg'
import p6i1 from '../images/project-single/p6i1.jpg'
import p6i2 from '../images/project-single/p6i2.jpg'

import processImg1 from '../images/project-single/process-1.jpg'
import processImg2 from '../images/project-single/process-2.jpg'
import processImg3 from '../images/project-single/process-3.jpg'
import processImg4 from '../images/project-single/process-4.jpg'
import processImg5 from '../images/project-single/process-5.png'
import processImg6 from '../images/project-single/process-6.jpg'


const Projects = [
   {
      Id:'1',
      pImg:pimg4,
      pheader:pheader4,
      ps1:p4i1,
      ps2:p4i2,
      processImg:processImg4,
      title:'Doppler', 
      subTitle:'Mobile App Design',
      location: 'US',
      type: 'iOS Design',
      duration: '4 Months',
      completion: '22 Feb 2024',
      overview: "I wanted to build a product geared towards educating individuals who are interested in trading digital assets.",
      strategy: "The best way to get people to learn how to trade cryptocurrency is to build an app that allows people to trade real prices without using real money",
      stratPoint1: 'Design an app that a beginner would use',
      stratPoint2: 'Mimic the feel of an established exchange for when users are ready to trade with real money',
      stratPoint3: 'Gamify the experience and allow users to connect with each other',
      approach: 'Take what works from similar apps, and simplify the process for new users to the field to digest',
      goalPoint1: 'Allow users to easily trade currency',
      goalPoint2: 'Increase community engagement',
      goalPoint3: 'Create a gamified experience to increase user rentention',
      resultPoint1: 'Users were able to navigate the MVP successfully',
      resultPoint2: 'Users found the community aspect to be intuitive',
      resultPoint3: 'High quality feedback was received to proceed into next phase of the project', 
      liveSite: 'Visit Live Site',
      siteURL: "https://www.dopplersim.com"     
   },
    {
       Id:'2',
       pImg:pimg1,
       pheader:pheader1,
       ps1:p1i1,
       ps2:p1i2,
       processImg:processImg1,
       title:'CycleHawk',  
       subTitle:'Product Design',  
       location: 'Washington D.C.',
       type: 'Product Design',
       duration: '3 Months',
       completion: '15 Apr 2021',
       overview: "CycleHawk is a Google Chrome extension that enables individuals to utilize virtual cards for their monthly subscription services. By consolidating their subscriptions into one, they can select their preferred due date, thereby streamlining their monthly statements.",
       strategy: "Our strategy was to pinpoint the typical user who would benefit greatly from utilizing virtual cards and handling their transactions through the app's dashboard. Developing the app for desktop initially allows us to gather valuable data that we can leverage later on when designing the mobile apps.",
       stratPoint1: 'Identify the user',
       stratPoint2: 'Find out what the behavioral priorities are',
       stratPoint3: 'Enhance user experience by user testing',
       approach: 'We chose to develop a dashboard that emphasizes the virtual cards and the outstanding balance, while also enabling users to efficiently handle their monthly payment and free trials.',
       goalPoint1: 'Users can intuitively configure their settings',
       goalPoint2: 'The information in regards to creating virtual cards is accessible',
       goalPoint3: 'Updating free trials is quick and easy for users',
       resultPoint1: 'Users were quickly able to update their settings',
       resultPoint2: 'The process of creating virtual cards was clear to users',
       resultPoint3: 'Successfully collected data to use when creating the mobile version of this app',

    },
    {
      Id:'3',
      pImg:pimg5,
      pheader:pheader5,
      ps1:null,
      ps2: null,
      processImg:processImg5,
      title:'SpaceX Companion', 
      subTitle:'Mobile App Design',
      location: 'Washington',
      type: 'Mobile App Design',
      duration: '5 Months',
      completion: '15 Aug 2018',
      overview: "I was contacted by a developer to assist in developing an informative app for SpaceX. While the backend and concept were already established, there was a pressing need to improve the user experience.",
      strategy: "My goal was to gain a better understanding of SpaceX fans and their desires for an app. Using this information, it is important to highlight the community's most significant needs and wants through the design.",
      stratPoint1: 'Survey the community and figure out what is important',
      stratPoint2: 'Create an interactive design that will empower the community',
      stratPoint3: 'Review the design with the community and revise accordingly',
      approach: "My plan is to develop an application that focuses on SpaceX's primary objective: Colonize Mars. Subsequently, we can expand the app's scope to encompass various topics, such as rockets, as well as news and updates.",
      goalPoint1: 'Create an experience that is intuitive for SpaceX fans',
      goalPoint2: 'Reach 10k active users',
      goalPoint3: 'Collect sufficient data for future projects',
      resultPoint1: 'By conducting user testing, we were able to improve the design and enhance its user-friendliness.',
      resultPoint2: 'We will monitor downloads once the app is built',
      resultPoint3: 'Created plans to capture user data once deployed',      
   },
    {
       Id:'4',
       pImg:pimg2,
       pheader:pheader2,
       ps1:p2i1,
       ps2:p2i2,
       processImg:processImg2,
       title:'Walter Sedona', 
       subTitle:'Web Design', 
       location: 'Seattle, WA',
       type: 'Web Design',
       duration: '2 Months',
       completion: '12 Jan 2020',
       overview: "The luxury car rental company made a request to develop a one-page website which would enable clients to reserve vehicles. Although the rental process was already in place, they wanted to incorporate it into their landing page, and also promote their offerings.",
       strategy: "We need to enable users to input their information to simplify the rental process, while also encouraging them to explore the landing page for any supplementary details.",
       stratPoint1: "Use the company's colors and design elements to create a form to onboard users into the rental process",
       stratPoint2: 'Create a layout that properly shows what the company offers',
       stratPoint3: 'Ensure that the experience is the same for both desktop and mobile',
       approach: "We referred to the company's current design standards and rental data to start the design.",
       goalPoint1: 'Allow users to easily start the rental process on the homepage',
       goalPoint2: 'Create content for the landing page and optimize it for SEO standards',
       goalPoint3: 'Increase user time on page for those who want to learn more',
       resultPoint1: 'Users were able to easily start the rental process',
       resultPoint2: 'Created a user-friendly layout',
       resultPoint3: 'Overall time on page slightly increased',   
    },
   
   {
      Id:'5',
      pImg:pimg6,
      pheader:pheader6,
      ps1:p6i1,
      ps2:p6i2,
      processImg:processImg6,
      title:'AMD Cloud Concept', 
      subTitle:'Web Design',
      location: 'N/A',
      type: 'Web Design',
      duration: '3 Weeks',
      completion: '11 Jun 2020',
      overview: "Create a landing page promoting AMD's cloud gaming service.",
      strategy: "Emphasize the streaming platform of AMD, highlighting its power and convenience.",
      stratPoint1: "Maintain AMD's branding throughout the design",
      stratPoint2: 'Highlight current library available to stream',
      stratPoint3: 'Advertise the services accessibilty from any device',
      approach: 'Design the landing page that focuses on gaming convenience while making it easy to download the app.',
      goalPoint1: 'Users understand the page upon landing',
      goalPoint2: 'Entice users to download the app',
      goalPoint3: 'Encourage users to use the app across multiple devices',
      resultPoint1: 'Through initial testing, users were able to understand the service before scrolling',
      resultPoint2: 'Gamers who are interested in such service knew where to download the app quickly.',
      resultPoint3: 'It was clear for users that the service can work on many low-end devices',      
   },
    {
      Id:'6',
      pImg:pimg3,
      pheader:pheader3,
      ps1:p3i1,
      ps2:p3i2,
      processImg:processImg3,
      title:'Cyphus',  
      subTitle:'Web Development', 
      location: 'Richmond, VA',
      type: 'Web Development',
      duration: '1 Month',
      completion: '01 Apr 2021',
      overview: "Cyphus, an IT consulting company, reached out in need of a brochure-style website.",
      strategy: "Cyphus provided me with the assets, content, and design library to help build a site to attract new clients.",
      stratPoint1: 'Reinforce design with a heatmap tool',
      stratPoint2: 'Give the user a strong idea of the business within the first 8 seconds of landing',
      stratPoint3: 'Make booking an initial call quick and easy',
      approach: 'It is important to use bold colors and language to reel in potential clients. First impressions are everything.',
      goalPoint1: 'Increase time on page',
      goalPoint2: 'Increase conversion',
      goalPoint3: 'Decrease bounce rate',
      resultPoint1: 'Time on page increased 18% YoY',
      resultPoint2: 'Conversions increased 5% YoY',
      resultPoint3: 'Bounce rate remained a steady 85% YoY',
      liveSite: 'Visit Live Site',
      siteURL: "https://cyphus.netlify.app/"    
   }

   
    
]

export default Projects;