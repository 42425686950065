// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";
import blogImg4 from "../images/blog/img-4.jpg";
import blogImg5 from "../images/blog/img-5.jpg";
import blogImg6 from "../images/blog/img-6.jpg";

import blogSingleImg1 from "../images/blog-details/img-1.jpg";
import blogSingleImg2 from "../images/blog-details/img-2.jpg";
import blogSingleImg3 from "../images/blog-details/img-3.jpg";
import blogSingleImg4 from "../images/blog-details/img-4.jpg";
import blogSingleImg5 from "../images/blog-details/img-5.jpg";
import blogSingleImg6 from "../images/blog-details/img-6.jpg";



const blogs = [
    {
        id: '1',
        title: 'The Rise of Dark Mode in Web Design',
        screens: blogImg1,
        description: 'A trend that is on the rise across websites and software is a layout theme called dark mode. It is a trend that has been on the rise as users are demanding it. Not to mention, dark mode can make a design look sleek and modern with a few lines of CSS.',
        author: 'Julian Conde',
        thumb:'Design',
        create_at: '24 Feb, 2020',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
        Url:'https://www.julianconde.com/blog/the-rise-of-dark-mode-in-web-design/',
    },
    {
        id: '2',
        title: 'Web Design Case Study: Etsy',
        screens: blogImg2,
        description: 'Etsy has been considered to have one of the most successful web designs on the internet in terms of user experience. They have quite an interesting story and have found a unique way to connect to customers.',
        author: 'Julian Conde',
        thumb:'Design',
        create_at: '14 Feb,2020',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        blClass:'format-standard-image',
        Url: "https://www.julianconde.com/blog/web-design-case-study-etsy/",
    },
    {
        id: '3',
        title: 'Evo Icons',
        screens: blogImg3,
        description: 'Are you in need of some customized icons for a project that you have in mind? Evo Icons has a small but effective collection of icon sheets available for download. These icons are free to use for personal and commercial projects.',
        author: 'Julian Conde',
        thumb:'Resource',
        create_at: '8 Nov,2019',
        blogSingleImg:blogSingleImg3,
        comment:'95',
        blClass:'format-video',
        Url: 'https://www.julianconde.com/blog/evo-icons-free-icon-sheets-for-ui-projects/',
    },
];
export default blogs;