
import React, { Fragment } from 'react';
import { Dialog, Grid, } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import './style.css'
import Contact from './contact';
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import RelatedProject from './related';


const ProjectSingle = ({ maxWidth, open, onClose, title, pImg, processImg, liveSite, siteURL, ps1, ps2, location, type, duration, completion, 
overview, strategy, stratPoint1, stratPoint2, stratPoint3, approach, goalPoint1, goalPoint2, goalPoint3, resultPoint1, resultPoint2,
resultPoint3  }) => {

    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <i className="fa fa-close"></i>
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });


    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={onClose}>

                </DialogTitle>
                <Grid className="modalBody modal-body project-modal">
                    <div className="tp-project-single-area">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 col-12">
                                    <div className="tp-project-single-wrap">
                                        <div className="tp-project-single-item">
                                            <div className="row align-items-center mb-5">
                                                <div className="col-lg-7">
                                                    <div className="tp-project-single-title">
                                                        <h3>{title} Project</h3>
                                                    </div>
                                                    <p>{overview}</p>
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="tp-project-single-content-des-right">
                                                        <ul>
                                                            <li>Location :<span>{location}</span></li>
                                                            <li>Client :<span>{title}</span></li>
                                                            <li>Project Type :<span>{type}</span></li>
                                                            <li>Duration :<span>{duration}</span></li>
                                                            <li>Completion :<span>{completion}</span></li>
                                                            <li><a href={siteURL}>{liveSite}</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tp-project-single-main-img">
                                                <ReactFancyBox
                                                    thumbnail={pImg}
                                                    image={pImg}
                                                />
                                            </div>
                                        </div>
                                        <div className="tp-project-single-item list-widget">
                                            <div className="row">
                                                <div>
                                                    <div className="tp-project-single-title">
                                                        <h3>Strategy</h3>
                                                    </div>
                                                    <p>{strategy}</p>
                                                    <ul>
                                                        <li>{stratPoint1}</li>
                                                        <li>{stratPoint2}</li>
                                                        <li>{stratPoint3}</li>
                                                    </ul>
                                                </div>
                                            <div style= {{textAlign: 'center', paddingTop: '10px'}}className="processImg">
                                                <img src={processImg}/>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="tp-project-single-item">
                                            <div className="tp-project-single-title">
                                                <h3>Approach</h3>
                                            </div>
                                            <p>{approach}</p>
                                        </div>
                                        <div className="tp-project-single-gallery">
                                            <div className="row mt-4">
                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <div className="tp-p-details-img">
                                                        <img src={ps1} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-12">
                                                    <div className="tp-p-details-img">
                                                        <img src={ps2} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tp-project-single-item list-widget">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="tp-project-single-title">
                                                        <h3>Goals</h3>
                                                    </div>
                                                    <ul>
                                                        <li>{goalPoint1}</li>
                                                        <li>{goalPoint2}.</li>
                                                        <li>{goalPoint3}</li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-6 list-widget-s">
                                                    <div className="tp-project-single-title">
                                                        <h3>Results</h3>
                                                    </div>
                                                    <ul>
                                                        <li>{resultPoint1}</li>
                                                        <li>{resultPoint2}</li>
                                                        <li>{resultPoint3}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <RelatedProject />
                                        <div className="tp-project-single-item">
                                            <div className="tp-project-contact-area">
                                                <div className="tp-contact-title">
                                                    <h2>Have project in mind? Let's discuss</h2>
                                                    <p>Get in touch with us to see how we can help you with your project</p>
                                                </div>
                                                <div className="tp-contact-form-area">
                                                    <Contact />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default ProjectSingle;

